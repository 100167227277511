/* eslint-disable no-nested-ternary */
const prod = process.env.NODE_ENV === 'production';

const live = process.env?.REACT_APP_DEV_LIVE === 'live';
const staging = process.env?.REACT_APP_DEV_LIVE === 'staging';

const local = 'http://192.168.0.102:1337/';
const baseUrl = live
  ? 'https://aws-lb.happykamper.io/'
  : staging
    ? 'https://sapi.happykamper.io/'
    : local;
// Hide console logs in production mode
if (prod) {
  console.log = () => {};
}
export default {
  siteName: 'React',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,

  apiUrl: baseUrl,
  csvUrl: `${baseUrl}ExmpleCSV`,
  sailsUrl: baseUrl,

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'e3tq72z0kgbpfay3800n06hqbu1su39v084nye4weku75ib6',
  dashboard: '/dashboard',
};
